@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root{
    
    --yellow: #ebc034;
    --yellow1: #cfa20d;
    --maroon: #800d22;
    --mint-dark : #05754a;
    --mint : #3bc28e;
    --green : #0d9b31;
    --greeny : #3eac5a;
    --red: #eb4034;
    --redi: #f0867e;
    --orange: #f4941e;
    --orangi: #fccd97;
    --blue: #2074cc;
    --bl1:rgb(114, 171, 231);
    --bg-torre: url("../assets/bg-torre.jpg");
    --bg-bodegona: url("../assets/bg-bodegona.jpg");
    --bg-multicanal: url("../assets/bg-multicanal.jpg");
    --bg-shell: url("../assets/bg-shell.jpg");
    --bg-super24: url("../assets/bg-super24.jpg");
    --bg-super7: url("../assets/super7.jpg");
    --bg-walmart: url("../assets/bg-walmart.jpg");
    --bg-texaco: url("../assets/bg-texaco.jpg");
    --bg-uno: url("../assets/bg-uno.jpg");
}
#root{
    height: 100vh;
}

body{
    height: 100vh !important;
    font-family: 'Montserrat', sans-serif !important;
}

.containerProds1{
    background-color: transparent;
    background-color: rgba(43, 43, 43, 0.644) !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.555) !important;
    padding-top: 2vh;
    padding-left: 2vh;
    padding-right: 2vh;
    overflow-y: scroll;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 50vh;
}
.containerProds2{
    background-color: transparent;
    background-color: rgba(43, 43, 43, 0.644) !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.555) !important;
    padding-top: 2vh;
    padding-left: 2vh;
    padding-right: 2vh;
    overflow-y: scroll;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 50vh;
}
.containerProds3{
    background-color: transparent;
    background-color: rgba(43, 43, 43, 0.644) !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.555) !important;
    padding-top: 2vh;
    padding-left: 2vh;
    padding-right: 2vh;
    overflow-y: scroll;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 50vh;
}
.containerProds4{
    background-color: transparent;
    background-color: rgba(43, 43, 43, 0.644) !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.555) !important;
    padding-top: 2vh;
    padding-left: 2vh;
    padding-right: 2vh;
    overflow-y: scroll;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 50vh;
}
.containerProds5{
    background-color: transparent;
    background-color: rgba(43, 43, 43, 0.644) !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.555) !important;
    padding-top: 2vh;
    padding-left: 2vh;
    padding-right: 2vh;
    overflow-y: scroll;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 50vh;
}

.containerProds6{
    background-color: transparent;
    background-color: rgba(43, 43, 43, 0.644) !important;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.555) !important;
    padding-top: 2vh;
    padding-left: 2vh;
    padding-right: 2vh;
    overflow-y: scroll;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 50vh;
}


.super24-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--red);
    background: var(--bg-super24);
    
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 30vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;
    padding-bottom: 0vh;

}

.texaco-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--red);
    background: var(--bg-texaco);
    
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 80vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;
}

.super7-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--red);
    background: var(--bg-super7);
    
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 45vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;
    padding-bottom: 0vh;

    

}

.shell-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--blue);
    background: var(--bg-shell);
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 65vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;

}
.form_containermc{
    box-sizing: border-box;
    width: 100% !important;
    background:
    linear-gradient(180deg, #e2bb18 0%, #0b0b0b 90%, #d5d5d5 100%);

    
}
.form_containerVerano{
    box-sizing: border-box;
    width: 100% !important;
    background:
    linear-gradient(180deg, #1404fe 0%, #009fe3 35%, #f8b556 100%);
}
.fixed{
    -webkit-transform: translateZ(0);
    -webkit-transform: translate3d(0, 0, 0);
-o-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.multicanal-bg{
    
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--yellow);
    background: var(--bg-multicanal);
    
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 80vh !important;
    
    padding-left: 8vh !important;
    padding-right: 8vh !important;

}

.laTorre-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background: var(--bg-torre);
    background-size: cover;
    background-color: var(--orange);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 75vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;

}

.bodegona-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--yellow);
    background: var(--bg-bodegona);
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 65vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;
}

.walmart-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--blue);
    background: var(--bg-walmart);
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 82vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;
}

.uno-bg{
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--blue);
    background: var(--bg-uno);
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 60vh !important;
    padding-left: 8vh !important;
    padding-right: 8vh !important;
}

.buttD7{
    margin-bottom: 6vh;
    width: 20vh;
}
.buttD{
    margin-bottom: 25vh;
    width: 20vh;
}



@media screen and (max-width:375px) {
    .buttD{
        /* margin-bottom: 70vh; */
    }
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
