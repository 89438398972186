

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'DancingScript';
  src: url('../src/assets/fonts/Dancing_Script/static/DancingScript-SemiBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DancingScript-Medium';
  src: url('../src/assets/fonts/Dancing_Script/static/DancingScript-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu-Medium';
  src: url('../src/assets/fonts/Ubuntu-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'PlayfairDisplay-Medium';
  src: url('../src/assets/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Whisper-Regular';
  src: url('../src/assets/fonts/Whisper/Whisper-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand-Medium';
  src: url('../src/assets/fonts/Quicksand/static/Quicksand-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNeue-Regular';
  src: url('../src/assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pacifico-Regular';
  src: url('../src/assets/fonts/Pacifico/Pacifico-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CedarvilleCursive-Regular';
  src: url('../src/assets/fonts/Cedarville_Cursive/CedarvilleCursive-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoColorEmoji';
  src: url('../src/assets/fonts/Noto_Color_Emoji/NotoColorEmoji-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'BeauRivage-Regular';
  src: url('../src/assets/fonts/Beau_Rivage/BeauRivage-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}